import { memo } from 'react';
import { Link } from 'react-router';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { useStandardApiListRequest } from 'my-core/hooks';
import { useFetchStudentsHelped } from 'my-core/lp-hooks';
import { coursesPath } from 'my-core/routes';

import SchoolCard from 'my-components/SchoolCard';
import { Container } from 'my-layout';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { searchSchools } from 'my-actions/SchoolActions';

export default memo(function HomePageSchoolsSection() {
  const fetchKey = 'fetch_homepage_schools_banner';
  const studentsHelped = useFetchStudentsHelped();

  const {
    fetching,
    requestStatus: fetchSchoolsStatus,
    results: schools,
  } = useStandardApiListRequest({
    actionCreator: searchSchools,
    limit: 10,
    params: {
      filter: { school_type: 'undergrad' }, // has_high_priority_courses: true },
      order_by: 'loc_and_popularity',
      fields: {
        school: ['name', 'short_name', 'slug', 'type', 'country_code', 'region_code', 'city', 'stats'],
      },
    },
    parentRequestKey: fetchKey,
    storeKey: 'schools',
  });

  if (fetchSchoolsStatus?.error) return;

  return (
    <div
      css={({ breakpoints, constants, palette, spacing }) => ({
        padding: spacing(5, 0, 2),
        overflow: 'hidden',
        '& .overline-text': { color: palette.text.secondary },
        '& > .schools-list': {
          overflowX: 'auto',
          padding: spacing(3, 3, 3, `max(${spacing(3)}, calc((100vw - ${constants.WRAPPER_MAX_WIDTH_LG}px) / 2))`),
          MsOverflowStyle: 'none', // IE and Edge
          scrollbarWidth: 'none', // Firefox
          '&::-webkit-scrollbar': { display: 'none' }, //Chrome, Safari and Opera
          display: 'flex',
          gap: spacing(3),
          '& > .school-card': { flex: '0 0 auto', minWidth: 270, maxWidth: 350 },
          '& > .see-more-btn': { alignSelf: 'center' },
        },
        [breakpoints.only('xs')]: {
          '& > .schools-list': {
            paddingLeft: spacing(2),
          },
        },
      })}
    >
      <Container>
        <Typography className="overline-text" variant="body3">
          Trusted by over {Math.floor(studentsHelped / 1000) || 1},000 students at schools like
        </Typography>
      </Container>
      <div className="schools-list">
        {fetching ?
          [...Array(20)].map((_, i) => <SchoolCard key={i} className="school-card" loading />)
        : schools.map(s => (
            <SchoolCard
              key={s.id}
              className="school-card"
              onClick={s => landingPageCTAClicked('schools_banner', 'school', s.name)}
              school={s}
            />
          ))
        }
        <Button
          className="see-more-btn"
          component={Link}
          onClick={() => landingPageCTAClicked('schools_banner', 'see_more')}
          to={coursesPath()}
        >
          See All
        </Button>
      </div>
    </div>
  );
});
